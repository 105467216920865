<app-header [showBackButton]="false" />
<ion-content>
  <div class="main-container app-content-max-width">
    <div class="filters-row">
      <app-search (searchFocused)="searchFocused($event)" />
    </div>
    <div [class.hidden]="isSearchFocused" class="shelves" data-cy="home-shelves-container">
      @if ((profileState.currentProfile$ | async)?.id) {
        <app-book-shelf [type]="shelfType.ContinueReading" />
      }
      <app-book-shelf [type]="shelfType.FeaturedActive" />
      <app-book-shelf [type]="shelfType.Collection" />
      @if ((profileState.currentProfile$ | async)?.isFamilyProfile === true) {
        <app-book-shelf [type]="shelfType.BedStory" [new]="true" data-cy="home-bed-story-shelf" />
      }
      <app-book-shelf [type]="shelfType.Popular" />
      <app-book-shelf [type]="shelfType.New" />
      <app-book-categories-shelves />
    </div>
  </div>
</ion-content>
